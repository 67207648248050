import { Link } from "react-router-dom";
import  { BrazillianReal } from "../../util";

const SourceBadge = ({source}) =>
(
  <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-sm font-medium text-green-700">
    <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
      <circle r={3} cx={3} cy={3} />
    </svg>
    {source}
  </span>
)

export default function PropertyGrid({ posts, setPosts, user, removeUserFavorite }) {

  // remove favorite property
  const handleFavorite = (e, id) => {
    removeUserFavorite(user.id, id)
    .then(() => {
      let _posts = posts.filter((post) => post.id !== id);
      setPosts(_posts);
    });
  };

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-6">
    {posts.map((item, index) => {
      return (
        <div
          className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-700 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500 w-full mx-auto"
          key={index}
        >
          <div className="md:flex">

            {/* IMAGEM E BOTAO DE FAVORITAR */}
            <div className="relative md:shrink-0">
              <img
                className="h-full w-full object-cover md:w-48"
                src={item.photos.length !== 0 ? item.photos[0] : "/images/property/6.jpg"}
                alt=""
              />
              <div className="absolute top-4 end-4">
                <button
                  to="#"
                  className="btn btn-icon bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-full text-red-600 dark:text-red-600"
                  onClick={(e) => handleFavorite(e, item.id)}
                >
                  <i className="mdi mdi-heart text-[20px]"></i>
                </button>
              </div>

              <div className="absolute bottom-4 start-4">
                <SourceBadge source={item.source} />
              </div>
              
            </div>

            {/* DETALHES DO IMOVEL */}
            <div className="p-6 w-full">
              <div className="md:pb-4 pb-6">
                <Link
                  to={`/property-detail/${item.id}`}
                  className="text-lg hover:text-green-600 font-medium ease-in-out duration-500"
                  data-id={item.id}
                >
                  {item.title}
                </Link>
              </div>

              <ul className="md:py-4 py-6 border-y border-slate-100 dark:border-gray-800 flex items-center justify-between list-none">
                <li className="flex items-center me-4">
                  <i className="mdi mdi-arrow-expand-all text-2xl me-2 text-green-600"></i>
                  <span>{item.total_area || item.building_area}m2</span>
                </li>

                <li className="flex items-center me-4">
                  <i className="mdi mdi-bed text-2xl me-2 text-green-600"></i>
                  <span>{item.bedrooms}</span>
                </li>

                <li className="flex items-center">
                  <i className="mdi mdi-shower text-2xl me-2 text-green-600"></i>
                  <span>{item.bathrooms}</span>
                </li>
              </ul>

              <ul className="md:pt-4 pt-6 flex justify-between items-center list-none">
                <li>
                  <span className="text-slate-400">Preço</span>
                  <p className="text-lg font-medium">{BrazillianReal.format(item.price)}</p>
                </li>

                <li>
                  <span className="text-slate-400">Localização</span>
                  <p className="text-lg font-medium">{item.location}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    })}
  </div>
)
}